import { addLocaleData } from "react-intl";
// todo - remove everything that comes from outside the lib folder(manage somehow)
import fr from "react-intl/locale-data/fr";

addLocaleData([...fr]);

const messages = {
  /*
    Before adding new keys here, add a key mapping in the keys.json file in this directory for
    intel and abstraction purposes.
   */
  link_in_button_error: "Impossible d'insérer un lien dans un bouton",
  allow_prescriber: "Autoriser votre prescripteur à voir ce Rapport",
  report_payment_message:
    "Votre paiement a été effectué avec succès! Le rapport sera envoyé à votre adresse e-mail.",
  already_paid: "Ce paiement a déjà été effectué.",
  both: "Les deux",
  paid: "Payé",
  not_paid: "Non payé",
  reports_paid: "Rapports payés",
  report_link: "Rapport (avec lien de paiement)",
  report_both: "Rapport(s)",
  questionnaire_message: "Message initial du questionnaire",
  image_delete: "Êtes-vous sûr de vouloir supprimer cette image?",
  image_delete_error:
    "Cette image ne peut pas être supprimée car elle est utilisée ailleurs ({entity}). Veuillez d'abord la retirer de là si vous voulez la supprimer.",
  question_image: "Question",
  delete_success: "{entity} supprimé avec succès!",
  signup: "Inscription",
  insert_url: "Insérer l'URL",
  download: "Télécharger",
  preview: "Aperçu",
  gallery: "Galerie",
  buttons: "Boutons",
  variables: "Variables",
  enlarge: "Agrandir",
  add_image: "Ajouter cette image",
  edit_text: "Modifier le Texte",
  custom_texts: "Textes Personnalisés",
  seasonal_reminder: "Rappel Saisonnier",
  prescriber_message: "Message au prescripteur",
  template_error: "Tous les Variables et Boutons dans la liste devraient être utilisés!",
  email_template_saved: "Modèle d'email enregistré",
  custom_text_saved: "Texte Personnalisé enregistré",
  email_subject: "Sujet de l'Email",
  edit_template: "Modifier le Modèle",
  email_templates: "Modèles d'Email",
  template: "Modèle",
  new_pacient: "Nouveau Patient",
  existing_pacient: "Pacient existant",
  send_reminder: "Envoyer Rappel Saisonnier",
  not_send: "Ne pas envoyer",
  user_name: "Nom",
  user_surname: "Nom de famille",
  job: "Emploi",
  birth: "Naissance",
  portuguese: "Portugais",
  french: "Français",
  error_prepay_remote:
    "Une erreur s'est produite lors du traitement de votre demande. Veuillez nous contacter directement.",
  default_questionnaire_price: "Prix par défaut pour SAYN1 + SAYN2 (en cents)",
  default_sayn1_price: "Prix par défaut pour SAYN1 (en cents)",
  default_sayn2_price: "Prix par défaut pour SAYN2 (en cents)",
  post_questionnaire_delay: "Délai de post-Questionnaire (en heures)",
  backoffice_values: "Valeurs éditables",
  max_observation_size: "La taille maximale de l'observation est de {max} caractères",
  add_observations: "Ajouter des Observations",
  observations_saved: "Observations enregistrées!",
  values_saved: "Valeurs enregistrées!",
  block_report: "Empêcher l'envoi du rapport",
  send_report: "Envoyer le Rapport",
  report_sent: "Rapport envoyé",
  sent: "Envoyé",
  not_sent: "Non envoyé",
  go_back: "Retour",
  observations: "observations / recommandations",
  all_rights_reserved: "Tous droits réservés",
  amplitude: "Amplitude",
  next: "Prochaine",
  loading: "Chargement",
  search: "Chercher",
  reset: "Réinitialiser",
  required: "Obligatoire",
  start: "Début",
  pacient: "Patient",
  prescriber: "Prescripteur",
  no_prescriber: "sans prescripteur",
  notes: "Notes",
  general_definitions: "Définition générale",
  help: "Aidez-moi",
  privacy_policy: "Politique de confidentialité",
  copyright: "copyright © 2019 Sensor",
  date: "Date",
  cancel: "Annuler",
  save: "Enregistrer",
  other: "Autre",
  create: "Créer",
  upload: "Télécharger",
  edit: "Modifier",
  are_you_sure: "Êtes-vous sûr?",
  yes: "Oui",
  no: "Non",
  price: "Prix",
  total_price: "Prix Total",
  language: "Langue",
  submit: "Soumettre",
  created_at: "Créé à",
  updated_at: "Mis à jour à",
  read_at: "Lire à",
  view: "Voir",
  delete: "Supprimer",
  answerOptions: "Réponses",
  dataBase: "Base de Données",
  ACTIVE: "Actif",
  DISABLED: "Désactivé",
  created_by: "Créé par",
  actions: "Actions",
  answers: "Réponses",
  completed: "Terminé",
  not_completed: "Non terminé",
  questionnaire: "Questionnaire",
  questions: "Questions",
  open: "Ouverte",
  multiple: "Plusieurs",
  text: "Texte",
  closed: "Fermé",
  close_window: "Vous pouvez fermer cette fenêtre maintenant.",
  single: "Unique",
  usersHeader: "Utilisateurs",
  history: "Histoire",
  answerQuestionnaire: "Répondre au questionnaire",
  status: "Statut",
  items: "Articles",
  user: "Utilisateur",
  up: "En haut",
  down: "Vers le bas",
  ok: "D'accord",
  criteria: "Critères",
  criteriaUsage: "Imputations",
  metaSkill: "Méta Compétences",
  principle: "Principes",
  code: "code",
  name: "Nom",
  question: "Question",
  answer: "Répondre",
  all: "Toute",
  meta_competences: "Méta Compétences",
  confirm: "Confirmer",
  return: "Revenir",
  invites: "Invitations",
  invite: "Inviter",
  send: "Envoyer",
  elements: "Éléments",
  attributes: "Attributs",
  energy_flow: "Flux d'énergie",
  sending: "Envoi en cours",
  feelings: "Sentiments",
  organs: "Organes",
  outputs: "Outputs",
  output_metadata: "Métadonnées de Output",
  input_metadata: "Métadonnées d'Entrée",
  metadata_bank: "Banque de Métadonnées",
  metaData: "Métadonnées",
  inputs: "Entrées",
  image_metadata: "Métadonnées d'image",
  image: "Image",
  output_information: "Informations de Output",
  none: "Aucun",
  add_input: "Créer Nouvelle Entrée",
  add_output: "Créer Nouvelle Output",
  input_name: "Nom d'entrée",
  input_content: "Contenu d'Entrée",
  type: "Type",
  output_content: "Contenu de Output",
  output_name: "Nom de Output",
  change_type: "Changer le Type",
  input_information: "Saisir des Informations",
  dowload_excel_users: "Exporter vers Excel",
  excel_users_title: "Liste_Utilisateurs_",
  printPDF: "Imprimer le PDF",
  element_attributes: "Attributs de l'Élément",
  attributes_bank: "Banque d'Attributs",
  sense: "Sens",
  duplicate: "Dupliquer",
  natural_elements: "Éléments Naturels",
  add_natural_element: "Créer Nouvelle Élément Naturel",
  create_new_natural_element: "Créer nouvel Élément Naturel",
  edit_natural_element: "Modifier l'Élément Naturel",
  add_season: "Créer Nouvelle Saison",
  create_new_season: "Créer Nouvelle Saison",
  edit_season: "Modifier la Saison",
  season: "Saison",
  add_character: "Ajouter Nouvelle Caractére",
  create_new_character: "Créer Nouvelle Caractére",
  edit_character: "Modifier la Caractére",
  character: "Caractéres",
  basic_settings: "Paramètres de base",
  security_settings: "Les paramètres de sécurité",
  first_name: "Prénom",
  last_name: "Nom de famille",
  birth_place: "Lieu de naissance",
  job_name: "Nom du travail",
  nationality: "Nationalité",
  gender: "Le sexe",
  birth_date: "Date de naissance",
  old_password: "Ancien mot de passe",
  new_password: "Choisissez un nouveau mot de passe",
  choose_password: "Choisissez un mot de passe",
  password: "Mot de passe",
  confirm_email: "Confirmez l'email",
  confirm_password: "Confirmez le mot de passe",
  change: "Changement",
  profile: "Profil de l'utilisateur",
  male: "Masculin",
  female: "Femelle",
  change_password: "Changer le mot de passe",
  invalid_password: "Mot de passe incorrect",
  password_changed: "Votre mot de passe a été changé",
  required_password: "Veuillez saisir votre mot de passe",
  match_password: "Les deux mots de passe que vous avez entrés ne correspondent pas!",
  password_recovery: "Récupération de mot de passe",
  password_recovery_msg:
    "Un email a été envoyé avec un lien pour changer votre mot de passe. Verifiez votre boîte de réception.",
  match_email: "Les deux emails que vous avez entrés ne correspondent pas!",
  input_email: "Veuillez saisir votre email!",
  invalid_email: "Email invalide",
  profile_updated: "Profil mis à jour",
  profile_updated_message: "Votre profil a été mis à jour",
  creation_date: "Date de Création",
  no_information: "Aucune Information",
  manage_question_criteria: "Gérer les Critères de la Question",
  logout: "Se déconnecter",
  login: "S'identifier",
  login_success: "Connexion réussie!",
  register: "S'inscrire",
  register_success: "Inscription réussie!",
  auth_questionnaire_msg:
    "Vous serez redirigé vers le Questionnaire. \nVous recevrez un lien dans votre email, au cas où vous voudriez revenir plus tard. \nCliquez sur OK pour continuer.",
  title: "Titre",
  users: "Utilisateurs",
  message: "Message",
  cta_questionnaire: "Répondre au Questionnaire",
  new_notification: "Nouvelle Notification",
  send_notification_to: "Envoyer Une Notification à",
  all_users: "Tous Les Utilisateurs",
  some_users: "Certains Utilisateurs",
  dynamic_keys: "Touches Dynamiques",
  confirm_message_disable: "Souhaitez-vous désactiver",
  confirm_message_enable: "Souhaitez-vous activer",
  confirm_this_male: "cette",
  confirm_this_female: "cette",
  enable: "activer",
  disable: "désactiver",
  answer_option: "Réponse",
  text_answer_placeholder: "Écrivez votre réponse ici",
  one_criteria: "critère",
  one_criteria_usage: "imputation",
  one_meta_skill: "compétence",
  one_principle: "principe",
  resending_invitation: "Renvoi de l'invitation",
  resending_invitation_to: "Renvoi de l'invitation à",
  to_fill_questionnaire: "pour remplir le questionnaire",
  clipboard: "Copier le code d'invitation",
  save_changes: "Sauvegarder les Modifications",
  discard_changes: "Annuler les Modifications",
  mobile_answer_component: "Composants",
  one_mobile_answer_component: "Composant",
  add_mobile_answer_component: "Créer Nouveau Composant",
  create_new_mobile_answer_component: "Créer Nouveau Composant",
  edit_mobile_answer_component: "Modifier le Composant",
  disable_mobile_answer_component: "Désactiver le Composant",
  enable_mobile_answer_component: "Activer le Composant",
  general: "Général",
  algorithm_values: "Valeurs d'Algorithme",
  skill_anchor: "Compétence Levier",
  skill_compensated: "Compétence Compens",
  skill_decompensated: "Compétence Décompens",
  criteria_management: "Gestion des Critères",
  free_in_app: "Gratuit dans l'Application",
  add_criteria: "Ajouter Nouveaux Critères",
  edit_criteria: "Modifier le Critère",
  free: "Gratuit",
  initial_date: "Date initiale",
  expire_date: "Date d'expiration",
  description: "Description",
  entities_assigned: "Entités Attribuées",
  entities: "Entités",
  effect_type: "Type d'effet",
  effect: "Effets",
  create_new_highlight_output: "Créer Nouvelle Highlight Output",
  create_new_highlight_question: "Créer Nouvelle Highlight Question",
  highlight_type: "Type de Highlight",
  create_new_highlight_type: "Créer Nouveau Type de Highlight",
  link_android: "Hyperlien Android",
  link_ios: "Hyperlien IOS",
  mobile_apps: "Applications Mobile",
  text_config: "Config Texte",
  assign_entities: "Attribuer des Entités",
  disable_text_config: "Désactiver configuration du texte",
  enable_text_config: "Activer configuration du texte",
  env: "Variables d'environnement",
  add_env: "Créer Nouvelle Variable d'Environnement",
  daily_challenge: "Challenge Quotidien",
  number_of_inputs: "Nombre d'Inputs",
  min_days_of_interval: "Nombre minimum de jours d'intervalle",
  add_highlight_type: "Ajouter Highlight",
  daily_challenge_saved: "Défi quotidien enregistré",
  create_new_highlight_questionnaire: "Créer Nouvelle Highlight Questionnaire",
  edit_highlight_questionnaire: "Modifier Highlight Questionnaire",
  edit_highlight_question: "Modifier Highlight Question",
  type_image: "IMAGE",
  type_text: "TEXTE",
  edit_question: "Modifier la Question",
  clean_filters: "Réinitialiser Filtres",
  static_string: "Strings Statiques",
  add_static_string: "Créer Nouvelle string statique",
  string_value: "Valuer de String",
  add_feeling: "Créer Nouveau Sentiment",
  edit_feeling: "Modifier Sentiment",
  add_organ: "Créer Nouveau Organe",
  edit_organ: "Modifier Organe",
  input_translation: "S'il vous plaît, entrez toutes les traductions",
  select_answer_type: "Veuillez sélectionner le type de réponse",
  set_criteria_as_free: "Définir les critères comme gratuits",
  remove_criteria_as_free: "Supprimer les critères",
  confirm_set_free: "Souhaitez-vous définir ce critère comme gratuit?",
  confirm_remove_free: "Souhaitez-vous supprimer ce critère?",
  free_criterias: "Critère gratuits",
  confirm_set_question_non_required:
    "Souhaitez-vous définir cette question comme non obligatoire?",
  confirm_set_question_required: "Souhaitez-vous définir cette question comme requis?",
  set_non_required: "Définir la question comme non obligatoire",
  set_required: "Définir la question selon les besoins",
  duplicate_questionnaire: "Le questionnaire a été dupliqué",
  add: "Ajouter",
  update_questionnaire: "Le questionnaire a été mis à jour",
  add_new_app: "Ajouter Nouvelle App",
  translate: "Traduire",
  delete_general_output:
    "Cette Output ne peut pas être supprimée car elle est utilisée dans l'application",
  alternative_report: "Rapport Alternatif",
  edit_static_string: "Modifier Texte Statique",
  edit_rich_text: "Modifier Texte Formaté",
  add_rich_text: "Créer Nouveau Texte Formaté",
  plain_text: "Texte Brut",
  rich_text: "Texte Formaté",
  create_new_answer_option: "Créer Nouvelle Réponse",
  edit_answer_option: "Modifier Réponse",
  relevant: "Pertinent",
  irrelevant: "Non pertinent",
  enable_criteria: "Activer les Critères",
  disable_criteria: "Désactiver les Critères",
  set_criteria_as_relevant: "Définir les critères comme pertinents",
  remove_criteria_as_relevant: "Supprimer les critères comme pertinents",
  confirm_set_relevant: "Souhaitez-vous définir ce critère comme pertinent?",
  confirm_remove_relevant: "Souhaitez-vous supprimer ce critère comme pertinent?",
  relevant_criteria: "Critères Pertinents",
  surname: "Nom de Famille",
  role: "Role",
  create_user: "Créer un utilisateur",
  edit_user: "Modifier utilisateur",
  week: "Semaine",
  sunday: "Dimanche",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  add_week: "Ajouter une semaine",
  general_insights: "Aperçus Généraux",
  resources_of_focus_insights: "Fonctionnalités de Focus Insights",
  act_quiz_call_to_action: "Questionnaire d'appel à l'action",
  general_criteria: "Critères généraux",
  self_criteria: "Critères propres",
  social_criteria: "Critères sociaux",
  professional_criteria: "Critères professionnels",
  report_and_react: "Signaler et réagir",
  heart_re_question: "Question du cœur",
  highlight_schedule: "Planification",
  delete_week: "supprimer la semaine",
  next_question: "Question Suivante",
  previous_question: "Question Précédente",
  done: "Finish Questionnaire",
  answer_saved: "Réponse a été enregistrée",
  import: "Importer",
  export: "Exporter",
  import_data: "Importer des données",
  file_imported: "Fichier exporté",
  data_exported: "Données exportées",
  error_exporting_data: "Erreur lors de l'exportation des données",
  invalid_questionnaire_email:
    "L'email n'est pas correct, veuillez le vérifier à nouveau",
  page_not_found: "Page non trouvée",
  error_general: "Une erreur s'est produite, veuillez réessayer plus tard",
  pay: "Payer",
  payment: "Paiement",
  payment_succeeded: "Paiement réussi!",
  payment_unsuccessful:
    "Votre paiement n'a pas été effectué avec succès. Essayez à nouveau, s'il vous plaît.",
  payment_processing: "Votre paiement est en cours de traitement.",
  pre_pay_invite: "Invitation prépayée",
  pre_pay_invite_msg: "À qui voulez-vous envoyer l'invitation?",
  error: "Erreur",
  export_data: "Exporter les données",
  invitation: "Invitation",
  disable_invitation: "Désactiver l'invitation",
  enable_invitation: "Activer l'invitation",
  algorithm_config: "Configuration d'algorithme",
  user_evolution: "Évolution des Utilisateurs",
  no_data: "Pas de Données",
  answer_type: "Type de Réponse",
  ADMIN: "Admin",
  CONSULTANT: "Consultant",
  PACIENT: "Patient",
  add_prescriber: "Ajouter/Modifier le Prescripteur",
  start_date: "Date de début",
  end_date: "Date de fin",
  field_required: "Champ Obligatoire",
  report: "Rapport",
  type_to_search: "Tapez pour rechercher",
  summary_of_resources: "Synthèse des ressources",
  sensorial_rhythms: "Rythmes Sensoriels",
  select_date: "Sélectionner une date",
  confirm_invitation: "Êtes-vous sûr de vouloir envoyer cette invitation?",
  confirm_answer: "Êtes-vous sûr de vouloir continuer?",
  confirm_prepayment: "Êtes-vous sûr de vouloir prépayer cette questionnaire?",
  free_invite: "Sélectionnez cette option pour rendre le questionnaire gratuit",
  sending_email_to: "Envoi d'un email à",
  sending_email: "Envoi d'un email...",
  to_fill_the_questionnaire: "pour remplir le questionnaire",
  sending_invitation: "Envoi d'une invitation",
  invitation_sent: "Invitation envoyée",
  an_email_was_sent_to: "Un email a été envoyé à",
  payment_page_title:
    "L'analyse de votre activité sensorielle est essentielle pour prendre soin de votre santé",
  payment_page_list_title:
    "En analysant votre activité sensorielle, vous rendez cela possible:",
  payment_page_list_item_one:
    "un traitement adapté à votre activité physiologique et biologique;",
  payment_page_list_item_two:
    "une adaptation éclairée des recommandations et des prescriptions;",
  payment_page_list_item_three:
    "une réponse organiquement réussie aux soins de santé dont vous avez besoin.",
  questionnaire_welcome_page_title: "Bienvenue dans votre système sensoriel",
  questionnaire_welcome_page_right_paragraph_one:
    "À l'instar d'une analyse sanguine, l'analyse SAYN révèle exactement ce que votre corps vous dit manifestement sur lui-même, même si vous n'en êtes pas conscient.",
  questionnaire_welcome_page_right_paragraph_two:
    "En analysant les données et les corrélations de votre organisme biologique, il sera possible à votre professionnel de la santé de comprendre clairement quel soin, quelle prescription ou quelle recommandation est le plus efficace pour vous à l'heure actuelle.",
  questionnaire_welcome_page_right_paragraph_three:
    "Votre activité sensorielle sera analysée à l'aide d'un questionnaire qui prendra environ 15/20 minutes à remplir. Toutefois, vous pouvez prendre autant de temps que vous le souhaitez. Pour une analyse réussie, il est essentiel que vous ayez ce temps pour vous.",
  questionnaire_welcome_page_right_list_title:
    "Avant de commencer, voici ce qu'il faut savoir:",
  questionnaire_welcome_page_right_list_item_one:
    "Répondez de manière absolument libre et intuitive, sans hésitation.",
  questionnaire_welcome_page_right_list_item_two:
    "L'important n'est pas ce qu'ils disent ou ont dit de vous, mais ce que vous ressentez pour vous-même.",
  questionnaire_welcome_page_right_list_item_three:
    "Faites appel à votre candeur et à votre spontanéité pour favoriser une lecture en parfaite adéquation avec votre organisme. Il n'y a pas de bonnes ou de mauvaises réponses.",
  questionnaire_welcome_page_right_list_item_four:
    "Le questionnaire doit être rempli dans son intégralité. Vous ne pouvez pas remplir une partie maintenant et une autre partie plus tard.",
  questionnaire_welcome_page_right_list_item_five:
    "Si vous hésitez sur une réponse, ne vous inquiétez pas, nous vous recommandons de passer simplement à la question suivante.",
  questionnaire_welcome_page_right_footnote:
    "Si vous ne voulez pas continuer maintenant : cliquez sur le lien que vous avez reçu par email pour revenir plus tard. Vous avez 30 jours pour remplir le questionnaire à partir de la date d'inscription.",
  questionnaire_welcome_start_button_text: "Commencer l'analyse",
  questionnaire_completed_page_title: "Votre analyse sera prête dans {hours} heures",
  questionnaire_completed_page_left_text:
    "Et il sera envoyé à votre adresse électronique pour que vous puissiez le partager avec votre professionnel de la santé. Pour plus d'informations sur la première analyse qui décode le système sensoriel en temps réel, voir saynlab.com",
  questionnaire_completed_page_right_text:
    "Recommandez des soins en parfaite adéquation avec la vraie nature de chaque individu, à commencer par ceux que vous aimez le plus. Tout ami ou membre de la famille peut bénéficier de cette analyse pour prendre soin de sa santé de manière personnalisée. Recommander, c'est prendre soin.",
  questionnaire_completed_page_submitted_text:
    "Vos données ont été transmises avec succès.",
  // BACKEND ERROR MESSAGES
  EMAIL_ALREADY_EXISTS: "Il existe déjà un utilisateur avec cet email",
  EMAIL_NOT_FOUND: "L'email n'a pas été trouvé. Veuillez vérifier à nouveau.",
  ACCESS_DENIED: "Accès refusé",
  USER_NOT_FOUND:
    "Utilisateur non trouvé. \nVeuillez vérifier si l'email est correct ou, alternativement, inscrivez-vous."
};

export default messages;
